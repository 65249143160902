import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.team-carousel-block');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(
        carouselBlock.querySelector('.team-carousel-block__carousel'),
        {
          centerInsufficientSlides: true,
          loop: true,
          slidesPerView: 1,

          breakpoints: {
            480: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1080: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          },

          navigation: {
            prevEl: carouselBlock.querySelector('.swiper-button-prev'),
            nextEl: carouselBlock.querySelector('.swiper-button-next'),
          },
        }
      );
    });
  }
});
